import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
import { BlogContainer } from "components/blog-container"
import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { Author } from "../../components/author"

const HalloweenTwitterCampaign = ({ location }) => {
  const title = "Twitterハロウィンキャンペーン事例まとめ5選"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【2022年】お手本にしたいTwitterハロウィンキャンペーン5選"
          pagedesc="お手本にしたいTwitterハロウィンキャンペーンを紹介します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/halloween-twitter-campaign.jpg"
          pagepath="/blog/halloween-twitter-campaign"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main className="">
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>このページは表示確認用です</h1>
            <div className="blog__sns-container--top">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月3日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="かぼちゃの横でハロウィンの仮装をしている犬"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/halloween-twitter-campaign.jpg"
                className="w-full"
              />
            </figure>
            <p>
              秋のイベントといえばハロウィンですね。毎年たくさんの企業や店舗がさまざまなハロウィンキャンペーンやプロモーションを展開しており、盛んに行われています。
            </p>
            <p>
              業界を問わず季節ごとに合わせたキャンペーンを行う企業も年々増加傾向にあり、キャンペーン開催が欠かせないイベントになっています。
            </p>
            <p>
              今回は過去にハロウィン期間中に行われたTwitterキャンペーンについてご紹介します。
            </p>
            <p>
              自社でハロウィンTwitterキャンペーンを企画する際にお役に立てますと幸いです。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    ハロウィンジャンボ宝くじ プレゼントキャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    サッポロポテト公式ハロウィンキャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    WEAR ZOZOクーポンが当たるハロウィンキャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    USJ 「#勝たんしかUSJ仮装」ハッシュタグ投稿キャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    ドン・キホーテ
                    フォロー＆リツイートとハッシュタグキャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    まとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">ハロウィンジャンボ宝くじ プレゼントキャンペーン</h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  【本日開始！】
                  <br />
                  豪華賞品が当たるキャンペーンを実施中♪
                  <br />
                  <br />①
                  <a href="https://twitter.com/takarakuji_qoo?ref_src=twsrc%5Etfw">
                    @takarakuji_qoo
                  </a>
                  をフォロー
                  <br />
                  ②この投稿をRT！結果がすぐにわかる
                  <br />
                  <br />
                  詳しくは→
                  <a href="https://t.co/UAqVVnbwX7">https://t.co/UAqVVnbwX7</a>
                  <br />
                  ※20歳以上の方が参加対象です
                  <a href="https://twitter.com/hashtag/%E3%83%8F%E3%83%AD%E3%82%A6%E3%82%A3%E3%83%B3%E3%82%B8%E3%83%A3%E3%83%B3%E3%83%9C%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ハロウィンジャンボキャンペーン
                  </a>{" "}
                  <a href="https://t.co/LgbydEmq5s">
                    pic.twitter.com/LgbydEmq5s
                  </a>
                </p>
                &mdash; 宝くじ公式アカウント (@takarakuji_qoo){" "}
                <a href="https://twitter.com/takarakuji_qoo/status/1046580458983583744?ref_src=twsrc%5Etfw">
                  October 1, 2018
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/takarakuji_qoo"
                  target="_blank"
                  rel="noreferrer"
                >
                  宝くじTwitter公式アカウント
                </a>
                では、インスタントウィン形式・フォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。当選者にはハロウィンジャンボが当たるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>インスタントウィン（フォロー＆リツイート）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/takarakuji_qoo"
                  target="_blank"
                  rel="noreferrer"
                >
                  宝くじTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. 当落結果をリプライ確認</p>
              <h3>結果通知</h3>
              <p>即時リプライ</p>
            </div>
            <div>
              <h2 id="id2">サッポロポテト公式ハロウィンキャンペーン</h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ＼その場で当たる／
                  <br />{" "}
                  <a href="https://twitter.com/hashtag/%E3%83%8F%E3%83%AD%E3%82%A6%E3%82%A3%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ハロウィン
                  </a>{" "}
                  キャンペーン🎃🦇
                  <br />
                  <br />
                  フォロー＆
                  <a href="https://twitter.com/hashtag/RT?src=hash&amp;ref_src=twsrc%5Etfw">
                    #RT
                  </a>{" "}
                  で1️⃣0️⃣名様に
                  <a href="https://twitter.com/hashtag/%E3%81%8A%E8%8F%93%E5%AD%90%E3%81%AE%E3%83%AA%E3%83%A5%E3%83%83%E3%82%AF?src=hash&amp;ref_src=twsrc%5Etfw">
                    #お菓子のリュック
                  </a>{" "}
                  が作れるおかしセットを
                  <a href="https://twitter.com/hashtag/%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">
                    #プレゼント
                  </a>{" "}
                  🎁
                  <br />
                  <br />
                  🌟応募方法
                  <br />①
                  <a href="https://twitter.com/CalbeeSapporoCP?ref_src=twsrc%5Etfw">
                    @CalbeeSapporoCP
                  </a>
                  をフォロー
                  <br />② 10/30 23:59までに{" "}
                  <a href="https://twitter.com/hashtag/RT?src=hash&amp;ref_src=twsrc%5Etfw">
                    #RT
                  </a>
                  <br />③ 当選者には即時DM📥
                  <br />
                  <br />
                  応募規約🔎
                  <a href="https://t.co/zZc77MGaxZ">
                    https://t.co/zZc77MGaxZ
                  </a>{" "}
                  <a href="https://t.co/eaxFK59l9A">
                    pic.twitter.com/eaxFK59l9A
                  </a>
                </p>
                &mdash; サッポロポテト公式 (@CalbeeSapporoCP){" "}
                <a href="https://twitter.com/CalbeeSapporoCP/status/1453919547417792514?ref_src=twsrc%5Etfw">
                  October 29, 2021
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/CalbeeSapporoCP"
                  target="_blank"
                  rel="noreferrer"
                >
                  サッポロポテトTwitter公式アカウント
                </a>
                では、インスタントウィン形式・フォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。結果はすぐにDMで通知されます。当選者にはお菓子のリュックが当たるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>インスタントウィン（フォロー＆リツイート）</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/CalbeeSapporoCP"
                  target="_blank"
                  rel="noreferrer"
                >
                  サッポロポテトTwitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. 当落結果をDMで確認</p>
              <h3>結果通知</h3>
              <p>即時DM</p>
            </div>
            <div>
              <h2 id="id3">WEAR ZOZOクーポンが当たるハロウィンキャンペーン</h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  🎃WEARハロウィン2018🎃
                  <br />
                  <br />
                  ＼総額60万円分のZOZOクーポン当たる／
                  <br />
                  WEAR公式ツイッターをフォロー&amp;このツイートをRTすると、ZOZOTOWNで使える1万円クーポンを60名様にプレゼント💫
                  <br />
                  ▼詳細はこちら
                  <a href="https://t.co/FJSwxlJ3qZ">https://t.co/FJSwxlJ3qZ</a>
                  <br />
                  <br />
                  今年もハイクオリティなハロウィンコーデが充実👻💕
                  <a href="https://t.co/qZ3eK4YPTi">
                    https://t.co/qZ3eK4YPTi
                  </a>{" "}
                  <a href="https://t.co/hqeetyp7xr">
                    pic.twitter.com/hqeetyp7xr
                  </a>
                </p>
                &mdash; WEAR (@wear_official){" "}
                <a href="https://twitter.com/wear_official/status/1054570410145013761?ref_src=twsrc%5Etfw">
                  October 23, 2018
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/wear_official"
                  target="_blank"
                  rel="noreferrer"
                >
                  WEAR Twitter公式アカウント
                </a>
                では、フォロー＆リツイートキャンペーンが行われました。公式Twitterアカウントをフォローし、対象ツイートをリツイートします。結果は後日DMで通知され、当選者にはZOZOTOWN1万円分クーポンが当たるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>フォロー&amp;リツイート</p>
              <h3>参加方法</h3>
              <p>
                1.{" "}
                <a
                  href="https://twitter.com/wear_official"
                  target="_blank"
                  rel="noreferrer"
                >
                  WEAR Twitter公式アカウント
                </a>
                をフォロー
              </p>
              <p>2. 対象ツイートをリツイート</p>
              <p>3. キャンペーン後に当選者にのみDM</p>
              <h3>結果通知</h3>
              <p>後日DM</p>
            </div>
            <div>
              <h2 id="id4">
                USJ 「#勝たんしかUSJ仮装」ハッシュタグ投稿キャンペーン
              </h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  🎃🎃明日はハロウィーン🎃🎃
                  <a href="https://twitter.com/hashtag/%E5%8B%9D%E3%81%9F%E3%82%93%E3%81%97%E3%81%8BUSJ%E4%BB%AE%E8%A3%85?src=hash&amp;ref_src=twsrc%5Etfw">
                    #勝たんしかUSJ仮装
                  </a>{" "}
                  をつけて、皆さんの思い出が詰まった仮装フォトを投稿ください😋✨
                  <br />
                  <br />
                  タイムラインをハロウィーンで盛り上げよう♪
                  <br />
                  抽選で素敵なプレゼントも❤【投稿キャンペーンは11/7迄！】
                  <a href="https://twitter.com/hashtag/USJ?src=hash&amp;ref_src=twsrc%5Etfw">
                    #USJ
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/NOLIMIT?src=hash&amp;ref_src=twsrc%5Etfw">
                    #NOLIMIT
                  </a>{" "}
                  <a href="https://twitter.com/hashtag/20th?src=hash&amp;ref_src=twsrc%5Etfw">
                    #20th
                  </a>
                </p>
                &mdash; ユニバーサル・スタジオ・ジャパン公式 (@USJ_Official){" "}
                <a href="https://twitter.com/USJ_Official/status/1454251714480152576?ref_src=twsrc%5Etfw">
                  October 30, 2021
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/USJ_Official"
                  target="_blank"
                  rel="noreferrer"
                >
                  ユニバーサル・スタジオ・ジャパン Twitter公式アカウント
                </a>
                では、フォトコンテストキャンペーンが行われました。USJで仮装をしている姿を撮影し、TwitterもしくはInstagramで「#勝たんしかUSJ仮装」のハッシュタグをつけて投稿します。当選者にはオリジナルグッズが当たるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <p>写真付きハッシュタグ投稿</p>
              <h3>参加方法</h3>
              <p>1. USJで仮装した写真を撮影</p>
              <p>2. 「勝たんしかUSJ仮装」のハッシュタグをつけて投稿</p>
              <p>3. キャンペーン終了後に当選者に通知</p>
              <h3>結果通知</h3>
              <p>後日当選者へ連絡</p>
            </div>
            <div>
              <h2 id="id5">
                ドン・キホーテ フォロー＆リツイートとハッシュタグキャンペーン
              </h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  🎃賞品総額160万円!150名様に大チャンス!
                  <a href="https://twitter.com/hashtag/%E3%83%89%E3%83%B3%E3%82%AD%E3%81%A7%E3%83%8F%E3%83%AD%E3%82%A6%E3%82%A3%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ドンキでハロウィン
                  </a>{" "}
                  豪華景品2大キャンペーン!
                  <br />
                  <br />
                  ✅総額150万円争奪ハロウィンフォトコンテスト開催!
                  <br />
                  今年は当選者が11名→50名に拡大!
                  <br />
                  →最優秀作品はドンキ商品券30万円分✨
                  <br />
                  <br />
                  ✅フォロー＆この投稿RTで応募完了!
                  <br />
                  →抽選100名にドンキ商品券1000円分が当たる{" "}
                  <a href="https://t.co/spTScvC9EY">
                    pic.twitter.com/spTScvC9EY
                  </a>
                </p>
                &mdash; 驚安の殿堂 ドン・キホーテ🐧 (@donki_donki){" "}
                <a href="https://twitter.com/donki_donki/status/1178836929644769281?ref_src=twsrc%5Etfw">
                  October 1, 2019
                </a>
              </blockquote>
              <h3>キャンペーン概要</h3>
              <p>
                <a
                  href="https://twitter.com/donki_donki"
                  target="_blank"
                  rel="noreferrer"
                >
                  ドン・キホーテ Twitter公式アカウント
                </a>
                では、フォトコンテストとフォロー&amp;リツイートキャンペーンが行われました。USJで仮装をしている姿を撮影し、TwitterもしくはInstagramで「#勝たんしかUSJ仮装」のハッシュタグをつけて投稿します。当選者にはオリジナルグッズが当たるキャンペーンです。
              </p>
              <h3>キャンペーンタイプ</h3>
              <ul>
                <li>フォトコンテスト</li>
                <li>フォロー&amp;リツイート</li>
              </ul>
              <h3>参加方法</h3>
              <ul>
                <li>
                  フォトコンテスト
                  <p>1. ハロウィンに関する写真を撮影</p>
                  <p>
                    2.
                    「#ハロウィン」と「#ドンキでハロウィン」の、計2つのハッシュタグをつけて投稿
                  </p>
                  <p>3. キャンペーン終了後に当選者に通知</p>
                </li>
                <li>
                  フォロー&amp;リツイート
                  <p>
                    1.{" "}
                    <a
                      href="https://twitter.com/donki_donki"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ドン・キホーテ公式アカウント
                    </a>
                    をフォロー
                  </p>
                  <p>2. 対象ツイートをリツイート</p>
                  <p>3. キャンペーン後に当選者にのみ連絡</p>
                </li>
              </ul>
              <h3>結果通知</h3>
              <ul>
                <li>
                  フォトコンテスト
                  <p>後日公式サイトで発表</p>
                </li>
                <li>
                  フォロー&amp;リツイート
                  <p>後日当選者への連絡</p>
                </li>
              </ul>
            </div>
            <div>
              <h2 id="id6">まとめ</h2>
              <p>
                フォロー＆リツイートでのインスタントウィンは応募のハードルが低く手軽に参加できるキャンペーンです。抽選結果もすぐにわかるため、参加者の興味、ワクワク感を高めることができます。
              </p>
              <p>
                一方で写真とハッシュタグ投稿のフォトコンテストではユーザーが作り出すコンテンツ（UGC）を生みだすことができ、たくさんの目にとまる可能性があるため話題作りにも最適です。また自社のLPにUGCを掲載することで、よりキャンペーンの盛り上がりを引き立たせることができます。
              </p>
              <p>PARKLoTでは今回ご紹介したキャンペーンに対応しております。</p>
              <div className="blog__border-highlight">
                <ul>
                  <li>
                    <Link
                      className="blog__more-article-link"
                      to={`/case-study/twitter/instant-win-follow-and-retweet`}
                    >
                      フォロー＆リツイート（インスタントウィン）
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="blog__more-article-link"
                      to={`/case-study/twitter/twitter-non-instant-win`}
                    >
                      フォロー＆リツイート（後日抽選）
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="blog__more-article-link"
                      to={`/product/hashtag-campaign`}
                    >
                      UGC（ハッシュタグ）
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="blog__more-article-link"
                      to={`/product/coupon-campaign`}
                    >
                      クーポン配布
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div>
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="momoko" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <Link to={`/blog/twitter-hashtag-campaign`}>
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </Link>
                </li>
                <li>
                  <Link to={`/blog/why-sns-marketing-is-necessary`}>
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </Link>
                </li>
                <li>
                  <Link to={`/blog/comparison-no-tool`}>
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </Link>
                </li>
                <li>
                  <Link to={`/blog/twitter-hashtag-campaign`}>
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                    6選
                  </Link>
                </li>
                <li>
                  <Link to={`/blog/examples-of-utilization`}>
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </Link>
                </li>
                <li>
                  <Link to={`/blog/why-parklot-is-inexpensive`}>
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </Link>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default HalloweenTwitterCampaign
