import React from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  LineIcon,
} from "react-share"

export const ShareButtons = ({ articleUrl, articleTitle }) => (
  <div className="mt-5 mb-6">
    <div className="flex justify-center">
      <TwitterShareButton title={articleTitle} via="PARKLoT1" url={articleUrl}>
        <div
          className="flex items-center rounded"
          style={{ background: "#00aced" }}
        >
          <TwitterIcon size={24} round />
          <span className="text-white font-bold text-xs pr-2">ツイート</span>
        </div>
      </TwitterShareButton>

      <FacebookShareButton url={articleUrl}>
        <div
          className="flex items-center rounded"
          style={{ background: "#3b5998" }}
        >
          <FacebookIcon size={24} round />
          <span className="text-white font-bold text-xs pr-2">シェア</span>
        </div>
      </FacebookShareButton>

      <LineShareButton url={articleUrl}>
        <div
          className="flex items-center rounded"
          style={{ background: "#00b800" }}
        >
          <LineIcon size={24} round />
          <span className="text-white font-bold text-xs pr-2">いいね</span>
        </div>
      </LineShareButton>
    </div>
  </div>
)
